// @ts-ignore
import React from "react";
import Img from "gatsby-image/withIEPolyfill";
import "./header-image.component.scss";
import { HeaderImageComponentProps } from "../../types/header-image-component.props";

export default function HeaderImageComponent(props: HeaderImageComponentProps) {
  return (
    <div className={"hero-image-container mx-auto"}>
      <Img className={"hero-image"} fluid={{ ...props.image, aspectRatio: 1920 / 640 }} />
    </div>
  );
}
