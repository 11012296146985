import "./left-image.component.scss"
import Img from "gatsby-image/withIEPolyfill"
// @ts-ignore
import React from "react"
import { LandingpageSectionProp } from "../../../types/landingpage-section.prop"

interface LandingpageSectionImageLeftProps {
  data: LandingpageSectionProp;
  key: number;
}

export default function LandingpageSectionImageLeft(props: LandingpageSectionImageLeftProps) {

  let isButtonRendering = !!props.data.button

  let slug = props.data.slug
  if (slug === "thin-wood") {
    slug = "thinwood"
  }

  isButtonRendering = slug === "thinwood";
  return (
    <div className={"background"}>
      <div className={"left-content"}>
        <div className={"image-container-left"}>
          <Img className={"image"}
               fluid={{ ...props.data.preview.preview_image.childImageSharp.fluid, aspectRatio: 540 / 444 }} />
        </div>
        <div className={"abstract"}>
          <h3 className={"heading"}>{props.data.preview.preview_title}</h3>
          <p className={"text"}>{props.data.preview.preview_description}</p>
          {isButtonRendering &&
          <a className={"button"} href={`./${slug}`}>{props.data.button}</a>}
        </div>
      </div>
    </div>
  )
}
