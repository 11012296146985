import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import HeaderImageComponent from "../components/header-image/header-image.component"
import Home from "../components/layouts/home"
import LandingpageSectionImageLeft from "../components/landingpagesection/left-image/left-image.component"
import LandingpageSectionImageRight from "../components/landingpagesection/right-image/right-image.component"
import * as React from "react"

export const query = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {slug: {eq: "home"}}}) {
      edges {
        node {
          frontmatter {
            hero_image {
              childImageSharp {
                fluid(webpQuality: 70, jpegQuality: 80, srcSetBreakpoints: [300, 600, 800, 1200, 1600, 1920], fit: COVER, maxWidth: 1920, maxHeight: 640) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            slug
            landingpageSections {
              button
              collection
            }
          }
        }
      }
    }
    collections: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "collection"}}}) {
      edges {
        node {
          frontmatter {
            slug
            preview {
              preview_title
              preview_description
              preview_image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface Data {
  allMarkdownRemark: {
    edges: [
      {
        node: {
          frontmatter: {
            hero_image: {
              childImageSharp: {
                fluid: FluidObject
              }
            },
            slug: string,
            landingpageSections: [{
              button: string;
              collection: string;
            }]
          }
        }
      }
    ];
  }
  collections: {
    edges: [{
      node: {
        frontmatter: {
          slug: string,
          preview: {
            preview_title: string,
            preview_description: string,
            preview_image: {
              childImageSharp: {
                fluid: FluidObject
              }
            },
          }
        }
      }
    }]
  }
}

interface Props {
  data: Data;
}

export default function IndexRoute(props: Props) {
  const selectedNode = props.data.allMarkdownRemark.edges[0]
  const heroImage = selectedNode.node.frontmatter.hero_image.childImageSharp.fluid
  const landingPageSections = selectedNode.node.frontmatter.landingpageSections

  return (
    <Home>
      <HeaderImageComponent
        image={heroImage} />
      {landingPageSections.map((value, index) => {
        const isLeft = index % 2 === 0
        const idk = props.data.collections.edges.find(value1 => value1.node.frontmatter.slug === value.collection)
        if (isLeft) {
          return <LandingpageSectionImageRight key={index} data={{ ...idk.node.frontmatter, button: value.button }} />
        } else {
          return <LandingpageSectionImageLeft key={index} data={{ ...idk.node.frontmatter, button: value.button }} />
        }
      })}
    </Home>
  )
}
