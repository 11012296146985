import "./right-image.component.scss"
import Img from "gatsby-image/withIEPolyfill"
// @ts-ignore
import React from "react"
import { LandingpageSectionProp } from "../../../types/landingpage-section.prop"

interface LandingpageSectionImageRightProps {
  data: LandingpageSectionProp;
  key: number;
}

export default function LandingpageSectionImageRight(props: LandingpageSectionImageRightProps) {

  let isButtonRendering = !!props.data.button

  let slug = props.data.slug
  if (slug === "thin-wood") {
    slug = "thinwood"
  }

  isButtonRendering = slug === "thinwood";

  return (
    <div className={"right-content"}>
      <div className={"abstract"}>
        <h3 className={"heading"}>{props.data.preview.preview_title}</h3>
        <p className={"text"}>{props.data.preview.preview_description}</p>
        {isButtonRendering &&
        <a className={"button"} href={`./${slug}`}>{props.data.button}</a>}
      </div>
      <div className={"image-container-right"}>
        <Img className={"image"}
             fluid={{ ...props.data.preview.preview_image.childImageSharp.fluid, aspectRatio: 540 / 444 }} />
      </div>
    </div>
  )
}
